.ant-table-thead > tr >th{
  color: white;
  background: #039719;
}

.table-row-light {
  background-color: #ffffff;
}

.table-row-dark {
  background-color: #D6D6D6;
  color: #ffffff;
}

.table-row-dark:hover {
  background-color: #D6D6D6;
  color: #ffffff;
}